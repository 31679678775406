import { AvField, AvForm } from "availity-reactstrap-validation"
import Switch from "react-switch"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Card, CardBody, CardSubtitle, Table } from "reactstrap"
import { CardTitle, TabContent, TabPane } from "reactstrap"
import { Col, Container, Label, Row } from "reactstrap"
import Select from "react-select"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

// Form Editor
import FormEditors from "./Editor"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

// Tags
// import TagsInput from 'react-tagsinput'
// import 'react-tagsinput/react-tagsinput.css'

import { WithContext as ReactTags } from "react-tag-input"
import "./style.css"

const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

//actions
import {
  createProduct,
  getArea_lists,
  getSettings,
  getCategories,
  getStoreList,
} from "store/actions"

import LanguageTab from "components/Common/LanguageTab"
import { LanguageSwitch } from "common/LanguageSwitch"
import DatePikerTime from "components/Common/TimePicker"
import MetaTag from "components/Common/Meta-tag"
import UseImageUploader from "components/imageUploader/useImageUploader"
import {
  lastSpaceRemover,
  useCheckSpecialChars,
} from "components/Common/LastSpaceRemover"

const CreateProduct = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    loading,
    createProductError,
    categoryList,
    categoryLoading,
    storeList,
    storesLoading,
    currency,
  } = useSelector(state => ({
    createProductError: state.Products.createProductError,
    loading: state.Products.loading,
    categoryList: state.Category.categories,
    categoryLoading: state.Category.loading,
    storeList: state.Stores.storeList,
    storesLoading: state.Stores.loading,
    currency: state.Settings.settings.company?.defaultCurrencySymbol,
  }))

  const [selectedMulti, setselectedMulti] = useState(null)
  const optionGroup = categoryList.map(category => {
    return {
      value: category._id,
      label: category.primaryLang.name,
    }
  })

  function handleMulti(categoryData) {
    setselectedMulti(categoryData)
    const arrangedCategories = categoryData.map(a => a.value)
    setCategories(arrangedCategories)
  }

  const [tags, setTags] = useState([])

  const suggestions = categoryList.map(category => {
    return {
      id: category._id,
      text: category.primaryLang.name,
    }
  })

  const handleDeleteTag = i => {
    setTags(tags.filter((tag, index) => index !== i))
    const arrangedKeywords = tags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleAddition = tag => {
    setTags([...tags, tag])
    const arrangedKeywords = [...tags, tag].map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()
    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    // re-render
    setTags(newTags)
    const arrangedKeywords = newTags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleTagClick = index => {
    console.log("The tag at index " + index + " was clicked")
  }

  // var options = [
  //   { value: "one", label: "One" },
  //   { value: "two", label: "Two" },
  // ]

  // const options = categoryList.map(category => {
  //   return {
  //     value: category._id,
  //     label: category.primaryLang.name
  //   };
  // });

  // const { area } = useSelector(state => ({
  //   area: state.Area.areas,
  // }))

  const [varients, setVarients] = useState([
    {
      value: "",
      unit: "kg",
      cost: 0,
      price: 0,
      offerPrice: 0,
      primePrice: 0,
      stock: 0,
    },
  ])

  const [selectDateTimeFrom, setSelectDateTimeFrom] = useState("00:00")
  const [selectDateTimeTo, setSelectDateTimeTo] = useState("00:00")

  const [imagesPreview, setImagesPreview] = useState([])
  const [taxPercentage, setTaxPercentage] = useState(0)
  // const [fileSize, setFileSize] = useState("")

  //baseData
  const [itemCode, setItemCode] = useState()
  const [barcode, setBarcode] = useState()

  const [maxOrderQuantity, setMaxOrderQuantity] = useState()
  const [minOrderQuantity, setMinOrderQuantity] = useState()

  // const [color, setColor] = useState()
  // const [size, setSize] = useState()
  const [primaryLangName, setPrimaryLangName] = useState("")
  const [primaryLangDescription, setPrimaryLangDescription] = useState("")
  const [secondaryLangName, setSecondaryLangName] = useState("")
  const [secondaryLangDescription, setSecondaryLangDescription] = useState("")
  const [primaryLangHighlights, setPrimaryLangHighlights] = useState("")
  const [secondaryLangHighlights, setSecondaryLangHighlights] = useState("")

  const [keywords, setKeywords] = useState([])
  const [categories, setCategories] = useState([])

  //packageData
  const [weight, setWeight] = useState()
  const [dimension, setDimension] = useState()
  const [packageSize, setPackageSize] = useState()
  const [areaList, setAreaList] = useState([])
  const [isBulkyProduct, setIsBulkyProduct] = useState(false)

  //shippingData
  const [isExtraChargeNeeded, setIsExtraChargeNeeded] = useState(false)
  const [extraCharge, setExtraCharge] = useState()

  const [isAvailableEveryWhere, setIsAvailableEveryWhere] = useState(true)

  //fittingData
  const [fittingCharge, setFittingCharge] = useState()
  const [isFittingNeeded, setIsFittingNeeded] = useState(false)
  const [isFitted, setIsFitted] = useState(false)

  //labels
  const [isReturnViable, setisReturnViable] = useState(false)
  const [isDailyProduct, setIsDailyProduct] = useState(false)
  const [isSpecialOffer, setIsSpecialOffer] = useState(false)
  const [isFeatured, setisFeatured] = useState(false)
  const [extras, setExtras] = useState([])

  //values
  const [taxValue, setTaxValue] = useState(0)
  const [isDiscounted, setIsDiscounted] = useState(false)
  const [discountPercentage, setDiscountPercentage] = useState(0)
  const [discountValue, setDiscountValue] = useState(0)
  const [price, setPrice] = useState(0)
  const [cost, setCost] = useState(0)
  const [profit, setProfit] = useState(0)
  const [sellingPrice, setSellingPrice] = useState(0)
  const [isTaxIncluded, setIsTaxIncluded] = useState(false)

  //stock
  const [stockValue, setStockValue] = useState(0)
  const [minStock, setMinStock] = useState(0)
  const [reorderQuantity, setReorderQuantity] = useState(0)

  const taxCategories = [0, 5, 8, 12, 15, 18, 28]

  const [hasVarients, setHasVarients] = useState(true)

  const [activeTab, setActiveTab] = useState("1")

  const isMult = LanguageSwitch()?.isMult

  function handleValidSubmit(e, values) {
    setPrimaryLangName(lastSpaceRemover(primaryLangName))

    const product = {
      store: values.store,
      images: imagesPreview,
      itemCode: itemCode,
      barcode: barcode,
      primaryLang: {
        name: lastSpaceRemover(primaryLangName),
        description: primaryLangDescription,
        highlights: primaryLangHighlights,
      },
      secondaryLang: {
        name: secondaryLangName,
        description: secondaryLangDescription,
        highlights: secondaryLangHighlights,
      },
      keywords,
      categories,
      varients,
      hasVarients: hasVarients,

      //values
      price: price,
      cost: cost,
      profit: profit,
      sellingPrice: sellingPrice,
      // isTaxIncluded : isTaxIncluded,
      taxValue: taxValue,
      // isDiscounted : isDiscounted,
      discountPercentage: discountPercentage,
      discountValue: discountValue,

      //stock
      stockValue: stockValue,
      minStock: minStock,
      reorderQuantity: reorderQuantity,

      //labels
      isReturnViable: isReturnViable,
      isDailyProduct: isDailyProduct,
      isSpecialOffer: isSpecialOffer,
      isFeatured: isFeatured,
      extras: extras,
      maxOrderQuantity: parseInt(maxOrderQuantity),
      minOrderQuantity: parseInt(minOrderQuantity),
      availableTimeFrom: selectDateTimeFrom,
      availableTimeTo: selectDateTimeTo,

      // packageData
      weight: weight,
      dimension: dimension,
      packageSize: packageSize,
      availableAreas: areaList,
      isBulkyProduct: isBulkyProduct,

      // shippingData
      isExtraChargeNeeded: isExtraChargeNeeded,
      extraCharge: extraCharge,
      isAvailableEveryWhere: isAvailableEveryWhere,

      // fittingData
      isFittingNeeded: isFittingNeeded,
      isFitted: isFitted,
      fittingCharge: fittingCharge,
    }
    console.log(product)
    dispatch(createProduct(product, history))
  }

  // const onChangeImageHandler = e => {
  //   const files = Array.from(e.target.files)

  //   function bytesToSize(bytes) {
  //     var sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  //     if (bytes == 0) return "0 Byte"
  //     var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  //     return {
  //       size: Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i],
  //       sizeInKb: Math.round(bytes / 1024),
  //     }
  //   }

  //   const { size, sizeInKb } = bytesToSize(e.target.files[0]?.size)

  //   setImagesPreview([])

  //   if (sizeInKb >= 4000) {
  //     setFileSize(size + " file size is too large - Max Limit 4MB")
  //   } else {
  //     setFileSize("")
  //     files.forEach(file => {
  //       const reader = new FileReader()

  //       reader.onload = () => {
  //         if (reader.readyState === 2) {
  //           setImagesPreview(oldArray => [
  //             ...oldArray,
  //             { image: reader.result },
  //           ])
  //         }
  //       }

  //       reader.readAsDataURL(file)
  //     })
  //   }
  // }

  // const addOrRemoveItem = (arr, setArray, value) => {
  //   const isPresent = arr.includes(value)
  //   var newArr = arr

  //   if (isPresent) {
  //     newArr = arr.filter(item => {
  //       return item.toString() !== value.toString()
  //     })
  //   } else {
  //     newArr = [...arr, value]
  //   }
  //   setArray(newArr)
  // }
  /////
  const costChange = v => {
    setCost(v)
    const sellPriceCalc =
      Number(price) + Number(taxValue) - Number(discountValue)
    setSellingPrice(sellPriceCalc)
    const profitValue = sellPriceCalc - v
    setProfit(profitValue)
  }

  const priceChange = v => {
    setPrice(v)
    const discoutValueCalc = (discountPercentage / 100) * v
    setDiscountValue(discoutValueCalc)
    const taxValueCalc = (taxPercentage / 100) * v
    setTaxValue(taxValueCalc)
    const sellPriceCalc =
      Number(v) + Number(taxValueCalc) - Number(discoutValueCalc)
    setSellingPrice(sellPriceCalc)
    const profitValue = sellPriceCalc - cost
    setProfit(profitValue)
  }

  const taxPercentageChange = v => {
    setTaxPercentage(v)
    const taxValueCalc = (v / 100) * price
    setTaxValue(taxValueCalc)
    const sellPriceCalc =
      Number(price) + Number(taxValueCalc) - Number(discountValue)
    setSellingPrice(sellPriceCalc)
    const profitValue = sellPriceCalc - cost
    setProfit(profitValue)
  }

  // const taxValueChange = (v) =>{
  //   setTaxValue(v);
  //   setTaxPercentage((v*100)/price);
  // }

  const discountPercentageChange = v => {
    setDiscountPercentage(v)
    const discoutValueCalc = (v / 100) * price
    setDiscountValue(discoutValueCalc)
    const sellPriceCalc =
      Number(price) + Number(taxValue) - Number(discoutValueCalc)
    setSellingPrice(sellPriceCalc)
    const profitValue = sellPriceCalc - cost
    setProfit(profitValue)
  }

  const discountValueChange = v => {
    setDiscountValue(v)
    setDiscountPercentage((v * 100) / price)
    const sellPriceCalc = Number(price) + Number(taxValue) - Number(v)
    setSellingPrice(sellPriceCalc)
    const profitValue = sellPriceCalc - cost
    setProfit(profitValue)
  }

  useEffect(() => {
    dispatch(getStoreList())
    dispatch(getCategories())
    dispatch(getArea_lists(1))
    dispatch(getSettings())
  }, [dispatch])

  // const handleLabel = (item1, setItem1, item2, setItem2) => {
  //   if (item1?.length >= 1) {
  //     setItem2([...item2, { text: item1, id: item2?.length + 1 }])
  //   }
  //   setItem1("")
  // }

  // const handleDelete = (id, item, setItem) => {
  //   setItem(item?.filter(i => i.id !== id))
  // }

  const validateName = v => {
    let name = v.target.value
    name = name.replace(/-/g, "")
    name = name.replace("/", "")
    name = name.replace(/[!@#$%^&*()\-';",./?\\|]/g, "")
    v.target.value = name

    setPrimaryLangName(name)
  }

  return (
    <>
      <MetaTag title="Create Product" />

      <div className="page-content">
        <Breadcrumbs title="Products" breadcrumbItem="Create Product" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
              }}
            >
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4 text_deals_green">
                        Store *
                      </CardTitle>
                      <Row>
                        <Col className="col-6 d-flex flex-wrap">
                          <div className="col-12 mb-3">
                            <AvField
                              type="select"
                              name="store"
                              errorMessage="Select Store"
                              validate={{
                                required: { value: true },
                              }}
                            >
                              <option>Select Store</option>
                              {storeList?.map((item, key) => (
                                <option key={key} value={item?._id}>
                                  {item?.storeName}
                                </option>
                              ))}
                            </AvField>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row xl="12">
                {/* baseData */}
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4 text_deals_green">
                        Base Data
                      </CardTitle>
                      <Row>
                        <Col className="col-6 d-flex flex-wrap">
                          <div className="col-4 mb-3">
                            <AvField
                              name="itemCode"
                              label="Item Code"
                              type="text"
                              onChange={v => {
                                setItemCode(v.target.value)
                              }}
                            />
                          </div>

                          <div className="col-4 mb-3 mx-3">
                            <AvField
                              name="barcode"
                              label="Barcode"
                              type="text"
                              onChange={v => {
                                setBarcode(v.target.value)
                              }}
                            />
                          </div>

                          {/* <div className="form-group">
                            <label>Images</label>

                            <div className="mt-2 custom-file">
                              <input
                                type="file"
                                name="product_images"
                                className="custom-file-input"
                                id="customFile"
                                onChange={onChangeImageHandler}
                                multiple
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFile"
                              >
                                Choose Images
                              </label>
                            </div>

                            {imagesPreview?.map((img, key) => (
                              <div key={key}>
                                <img
                                  src={img?.image}
                                  alt="Images Preview"
                                  className="m-1 P-2 mr-2"
                                  width="20%"
                                />
                              </div>
                            ))}

                            {fileSize && (
                              <p className="text-danger">{fileSize}</p>
                            )}
                          </div> */}
                        </Col>

                        <Col
                          className="col-6 d-flex flex-wrap "
                          style={{ flexDirection: "column" }}
                        >
                          <Row className="dailyProductParent mb-2">
                            <Col className="parent_dateRange col-12">
                              <div className={"align-items-center d-flex mb-1"}>
                                <Label check for="checkbox">
                                  {" "}
                                  Daily Product :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mx-3"
                                  onColor="#626ed4"
                                  onChange={v => {
                                    setIsDailyProduct(v)
                                  }}
                                  checked={isDailyProduct}
                                />
                              </div>
                              {isDailyProduct ? (
                                <div className="d-flex flex-wrap align-items-start justify-content-start mb-2 mt-2 timePickerWrapper">
                                  <div className="d-flex flex-column">
                                    <label>Available From </label>
                                    <DatePikerTime
                                      setState={setSelectDateTimeFrom}
                                      state={selectDateTimeFrom}
                                    />
                                    <span className="my-1 text-info">
                                      Enter time in 24 hours
                                    </span>
                                  </div>
                                  <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </div>
                                  <div className="d-flex flex-column">
                                    <label>Available To </label>
                                    <DatePikerTime
                                      setState={setSelectDateTimeTo}
                                      state={selectDateTimeTo}
                                    />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <div className="align-items-center d-flex">
                              <Label check for="checkbox">
                                {" "}
                                Special Offer :
                              </Label>
                              <Switch
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                className="me-1 mb-sm-8 mx-3"
                                onColor="#626ed4"
                                onChange={v => {
                                  setIsSpecialOffer(v)
                                }}
                                checked={isSpecialOffer}
                              />
                            </div>
                          </Row>
                          {isSpecialOffer ? (
                            <Row>
                              <div className="col-4">
                                <AvField
                                  name="minOrderQuantity"
                                  label="Min Order Quantity"
                                  type="number"
                                  onChange={v => {
                                    setMinOrderQuantity(v.target.value)
                                  }}
                                />
                              </div>
                              <div className="col-4 mb-2 mx-3">
                                <AvField
                                  name="maxOrderQuantity"
                                  label="Max Order Quantity"
                                  type="number"
                                  onChange={v => {
                                    setMaxOrderQuantity(v.target.value)
                                  }}
                                />
                              </div>
                            </Row>
                          ) : (
                            ""
                          )}
                          <div className="mb-3 align-items-center d-flex">
                            <Label check for="checkbox">
                              {" "}
                              Return Viable :
                            </Label>
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              className="me-1 mb-sm-8 mx-3"
                              onColor="#626ed4"
                              onChange={v => {
                                setisReturnViable(v)
                              }}
                              checked={isReturnViable}
                            />
                          </div>
                        </Col>

                        <div className="">
                          <Label className="mb-1">Images</Label>
                          <UseImageUploader
                            imageSize={4}
                            imagesPreview={imagesPreview}
                            setImagesPreview={setImagesPreview}
                            uploadUrl={"/product/admin/upload/image"}
                            deleteUrl={"/product/admin/delete/image"}
                          />
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row xl="12">
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      {isMult && (
                        <LanguageTab
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      )}
                      <TabContent activeTab={activeTab} className="">
                        <TabPane tabId="1">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.name"
                                    label="Name *"
                                    type="text"
                                    onKeyUp={v => validateName(v)}
                                    errorMessage="Invalid product name"
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </div>

                                {/* //Description */}
                                <div className="col-12 mb-3">
                                  <Label
                                    htmlFor="lbltype"
                                    className="form-label mt-2"
                                  >
                                    Description
                                  </Label>
                                  <Col sm="12" className="mt-2">
                                    {primaryLangDescription &&
                                    primaryLangDescription != undefined ? (
                                      <FormEditors
                                        content={primaryLangDescription}
                                        setContent={setPrimaryLangDescription}
                                      />
                                    ) : (
                                      <>
                                        <FormEditors
                                          content={primaryLangDescription}
                                          setContent={setPrimaryLangDescription}
                                        />
                                      </>
                                    )}
                                  </Col>
                                </div>

                                {/* <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.description"
                                    label="Description"
                                    type="textarea"
                                    errorMessage="Invalid description"
                                    rows="7"
                                    onChange={v => {
                                      setPrimaryLangDescription(v.target.value)
                                    }}
                                  />
                                </div> */}

                                {/* Highlights */}
                                <div className="col-12 mb-3">
                                  <Label
                                    htmlFor="lbltype"
                                    className="form-label"
                                  >
                                    Highlights
                                  </Label>
                                  <Col sm="12" className="mt-4">
                                    <FormEditors
                                      content={primaryLangHighlights}
                                      setContent={setPrimaryLangHighlights}
                                    />
                                  </Col>
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="2">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Name</CardSubtitle>
                                    <CardSubtitle>اسم</CardSubtitle>
                                  </div>
                                  <AvField
                                    name="secondaryLang.name"
                                    label=""
                                    type="text"
                                    onChange={v => {
                                      setSecondaryLangName(v.target.value)
                                    }}
                                  />
                                </div>
                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Description</CardSubtitle>
                                    <CardSubtitle>وصف</CardSubtitle>
                                  </div>
                                  <AvField
                                    name="secondaryLang.description"
                                    label=""
                                    type="textarea"
                                    onChange={v => {
                                      setSecondaryLangDescription(
                                        v.target.value
                                      )
                                    }}
                                    rows="7"
                                  />
                                </div>

                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Highlights</CardSubtitle>
                                    <CardSubtitle>يسلط الضوء</CardSubtitle>
                                  </div>
                                  <Col sm="12" className="mt-4">
                                    <FormEditors
                                      content={secondaryLangHighlights}
                                      setContent={setSecondaryLangHighlights}
                                    />
                                  </Col>
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* classifications */}
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      {/* <CardTitle
                        className="h4 mb-4"text_deals_green
                        
                      >
                        Classifications
                      </CardTitle> */}
                      <Row>
                        <Col xl="12">
                          <Row>
                            <div className="mb-3">
                              <label className="control-label">
                                Categories
                              </label>
                              <Select
                                value={selectedMulti}
                                isMulti={true}
                                onChange={e => {
                                  handleMulti(e)
                                }}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                              />
                            </div>

                            <div className="col-12 mb-3">
                              <Label htmlFor="lbltype" className="form-label">
                                Keywords
                              </Label>
                              <ReactTags
                                tags={tags}
                                placeholder="Type TEXT and enter"
                                suggestions={suggestions}
                                delimiters={delimiters}
                                handleDelete={handleDeleteTag}
                                handleAddition={handleAddition}
                                handleDrag={handleDrag}
                                handleTagClick={handleTagClick}
                                // inputFieldPosition="inline"
                                autocomplete
                                allowUnique
                                inline
                              />
                            </div>
                            {/* <Col className="col-12 d-flex flex-wrap">
                              <div className="col-3 mb-3 mx-3">
                                <AvField
                                  type="select"
                                  name="category"
                                  label="Category"
                                  onChange={v => {
                                    setCategory(v.target.value)
                                  }}
                                  // helpMessage="Select a category"
                                  // errorMessage="Select a category"
                                  // validate={{
                                  //   required: { value: true },
                                  // }}
                                >
                                  <option>Select a category</option>
                                  {categoryList?.map((item, key) => (
                                    <option key={key} value={item._id}>
                                      {item.primaryLang?.name}
                                    </option>
                                  ))}
                                </AvField>
                              </div>
                            </Col> */}
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* classifications */}
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        {hasVarients && (
                          <Col className="col-12 d-flex flex-wrap">
                            <Col md="12" className=" mt-2 mb-2">
                              <CardTitle className="h4 mb-4 text_deals_green d-flex align-items-center">
                                Product varients
                                <button
                                  className="mx-2 btn bg-light d-flex align-items-center justify-content-center"
                                  onClick={e => {
                                    e.preventDefault()
                                    setVarients(currentVarient => [
                                      ...currentVarient,
                                      {
                                        value: "",
                                        unit: "kg",
                                        cost: 0,
                                        price: 0,
                                        offerPrice: 0,
                                        primePrice: 0,
                                        stock: 0,
                                      },
                                    ])
                                  }}
                                >
                                  <i
                                    className="bx bx-plus"
                                    style={{ fontSize: "20px" }}
                                  ></i>
                                </button>
                              </CardTitle>
                              <div className="table-responsive">
                                <Table
                                  responsive
                                  striped
                                  className="table-nowrap mb-0"
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        colSpan={2}
                                        className="bg-gray text-center"
                                      >
                                        Product
                                      </th>
                                      <th
                                        colSpan={4}
                                        className="text-center bg-green"
                                      >
                                        Prices ({currency})
                                      </th>
                                      <th className="bg-yellow text-center">
                                        Stock (Count)
                                      </th>
                                      <th className="bg-lightYellow text-center">
                                        Remove
                                      </th>
                                    </tr>
                                    <tr>
                                      <th className="bg-lightGray text-center">
                                        Value
                                      </th>
                                      <th className="bg-lightGray text-center">
                                        Unit
                                      </th>

                                      <th className="bg-lightGreen">Cost</th>
                                      <th className="bg-lightGreen">Price</th>
                                      <th className="bg-lightGreen">
                                        Offer Price
                                      </th>
                                      <th className="bg-lightGreen">
                                        Prime Price
                                      </th>

                                      <th className="bg-lightYellow text-center">
                                        Stock
                                      </th>
                                    </tr>
                                  </thead>
                                  {varients?.map((s, key) => (
                                    <tbody key={key} className="editor_input">
                                      <tr>
                                        <td className="bg-lightGray text-center value_input">
                                          <AvField
                                            name="varient.value"
                                            type="text"
                                            value={s.value}
                                            onChange={e => {
                                              const value = e.target.value
                                              setVarients(currentVarient =>
                                                currentVarient.map(x =>
                                                  x === s
                                                    ? {
                                                        ...x,
                                                        value,
                                                      }
                                                    : x
                                                )
                                              )
                                            }}
                                          />
                                        </td>
                                        <td className="bg-lightGray text-center vertical-middle">
                                          <div className="d-flex align-items-center unit_select">
                                            <AvField
                                              name="varient.unit"
                                              type="select"
                                              value={s.unit}
                                              onChange={e => {
                                                const unit = e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          unit,
                                                        }
                                                      : x
                                                  )
                                                )
                                              }}
                                            >
                                              {UnitsList?.map((item, key) => (
                                                <option key={key} value={item}>
                                                  {item}
                                                </option>
                                              ))}
                                            </AvField>
                                            <i className="fas fa-angle-down mx-1"></i>
                                          </div>
                                        </td>
                                        <td className="bg-lightGreen">
                                          <AvField
                                            name="varient.cost"
                                            type="number"
                                            min={0}
                                            value={s.cost}
                                            onChange={e => {
                                              const cost = e.target.value
                                              setVarients(currentVarient =>
                                                currentVarient.map(x =>
                                                  x === s
                                                    ? {
                                                        ...x,
                                                        cost: cost
                                                          ? parseInt(cost)
                                                          : 0,
                                                      }
                                                    : x
                                                )
                                              )
                                            }}
                                          />
                                        </td>
                                        <td className="cursor-pointer bg-lightGreen ">
                                          <AvField
                                            name="varient.price"
                                            type="number"
                                            min={0}
                                            value={s.price}
                                            onChange={e => {
                                              const price = e.target.value
                                              setVarients(currentVarient =>
                                                currentVarient.map(x =>
                                                  x === s
                                                    ? {
                                                        ...x,
                                                        price: price
                                                          ? parseInt(price)
                                                          : 0,
                                                      }
                                                    : x
                                                )
                                              )
                                            }}
                                          />
                                        </td>
                                        <td className="bg-lightGreen">
                                          <AvField
                                            name="varient.offerPrice"
                                            type="number"
                                            value={s.offerPrice}
                                            onChange={e => {
                                              const offerPrice = e.target.value
                                              setVarients(currentVarient =>
                                                currentVarient.map(x =>
                                                  x === s
                                                    ? {
                                                        ...x,
                                                        offerPrice: offerPrice
                                                          ? parseInt(offerPrice)
                                                          : 0,
                                                      }
                                                    : x
                                                )
                                              )
                                            }}
                                          />
                                        </td>{" "}
                                        <td className="bg-lightGreen">
                                          <AvField
                                            name="varient.primePrice"
                                            type="number"
                                            value={s.primePrice}
                                            onChange={e => {
                                              const primePrice = e.target.value
                                              setVarients(currentVarient =>
                                                currentVarient.map(x =>
                                                  x === s
                                                    ? {
                                                        ...x,
                                                        primePrice: primePrice
                                                          ? parseInt(primePrice)
                                                          : 0,
                                                      }
                                                    : x
                                                )
                                              )
                                            }}
                                          />
                                        </td>{" "}
                                        <td className="bg-lightYellow text-center">
                                          <div className="d-flex align-items-center mx-auto w-fit">
                                            <AvField
                                              name="varient.stock"
                                              type="number"
                                              value={s.stock}
                                              onChange={e => {
                                                const stock = e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          stock: stock
                                                            ? parseInt(stock)
                                                            : 0,
                                                        }
                                                      : x
                                                  )
                                                )
                                              }}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <button
                                            className="mx-2 btn"
                                            onClick={e => {
                                              e.preventDefault()
                                              setVarients(currentVarient =>
                                                currentVarient.filter(
                                                  x => x !== s
                                                )
                                              )
                                            }}
                                          >
                                            <i
                                              className="bx bx-trash"
                                              style={{
                                                fontSize: "20px",
                                              }}
                                            ></i>
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))}
                                </Table>
                              </div>
                            </Col>
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* values */}
              {!hasVarients && (
                <Row>
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <CardTitle className="h4 mb-4 text_deals_green">
                          Values
                        </CardTitle>
                        <Row>
                          <Col xl="6">
                            <Row>
                              <Col className="col-12 d-flex flex-wrap ">
                                <div className="col-4 mb-3 mx-3">
                                  <AvField
                                    name="cost"
                                    label="Cost"
                                    type="number"
                                    value={cost}
                                    onChange={v => {
                                      costChange(v.target.value)
                                    }}
                                  />
                                </div>
                                <div className="col-4 mb-3 mx-3">
                                  <AvField
                                    name="price"
                                    label="Price"
                                    type="number"
                                    value={price}
                                    onChange={v => {
                                      priceChange(v.target.value)
                                    }}
                                  />
                                </div>
                              </Col>

                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-6 mb-3 mx-3">
                                  <Label check for="checkbox">
                                    Is Tax included :
                                  </Label>
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2 mx-3"
                                    onColor="#626ed4"
                                    onChange={v => {
                                      setIsTaxIncluded(v)
                                    }}
                                    checked={isTaxIncluded}
                                  />
                                </div>
                              </Col>

                              {isTaxIncluded && (
                                <Col className="col-12 d-flex flex-wrap">
                                  <div className="col-4 mb-3 mx-3">
                                    <AvField
                                      type="select"
                                      name="taxCategory"
                                      label="Tax Category"
                                      onChange={v => {
                                        taxPercentageChange(v.target.value)
                                      }}
                                    >
                                      <option>Select a Tax category</option>
                                      {taxCategories?.map((item, key) => (
                                        <option key={key} value={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </AvField>
                                  </div>
                                  <div className="col-4 mb-3 mx-3">
                                    <AvField
                                      name="taxValue"
                                      label="Tax Value"
                                      type="number"
                                      value={taxValue}
                                      // onChange = {(v)=> {taxValueChange(v.target.value)}}
                                      readOnly
                                    />
                                  </div>
                                </Col>
                              )}

                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-6 mb-3 mx-3">
                                  <Label check for="checkbox">
                                    {" "}
                                    Is Discounted :
                                  </Label>
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2 mx-3"
                                    onColor="#626ed4"
                                    onChange={v => {
                                      setIsDiscounted(v)
                                    }}
                                    checked={isDiscounted}
                                  />
                                </div>
                              </Col>

                              {isDiscounted && (
                                <Col className="col-12 d-flex flex-wrap">
                                  <div className="col-4 mb-3 mx-3">
                                    <AvField
                                      name="discountPercentage"
                                      label="Discount %"
                                      type="number"
                                      value={discountPercentage}
                                      onChange={v => {
                                        discountPercentageChange(v.target.value)
                                      }}
                                    />
                                  </div>
                                  <div className="col-4 mb-3 mx-3">
                                    <AvField
                                      name="discountValue"
                                      label="Discount Value"
                                      type="number"
                                      value={discountValue}
                                      onChange={v => {
                                        discountValueChange(v.target.value)
                                      }}
                                    />
                                  </div>
                                </Col>
                              )}

                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-4 mb-3 mx-3">
                                  <AvField
                                    name="sellingPrice"
                                    label="Selling Price"
                                    type="number"
                                    value={sellingPrice}
                                    readOnly
                                  />
                                </div>
                                <div className="col-4 mb-3 mx-3">
                                  <AvField
                                    name="profit"
                                    label="Profit"
                                    type="number"
                                    value={profit}
                                    // onChange = {(v)=> {costChange(v.target.value)}}
                                    readOnly
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl="6">
                            <Row>
                              <Col className="col-12 d-flex flex-wrap ">
                                <div className="col-4 mb-3 mx-3">
                                  <AvField
                                    name="stockValue"
                                    label="Stock"
                                    type="number"
                                    onChange={v => {
                                      setStockValue(v.target.value)
                                    }}
                                  />
                                </div>
                                <div className="col-4 mb-3 mx-3">
                                  <AvField
                                    name="minStock"
                                    label="Min Stock"
                                    type="number"
                                    onChange={v => {
                                      setMinStock(v.target.value)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-4 mb-3 mx-3">
                                  <AvField
                                    name="reorderQuantity"
                                    label="Reorder Quantity"
                                    type="number"
                                    onChange={v => {
                                      setReorderQuantity(v.target.value)
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}

              {/* packageData */}
              <Row>
                {/* <Col xl="6">
                  <Card>
                    <CardBody style={{ minHeight: "200px" }}>
                      <CardTitle
                        className="h4 mb-4"text_deals_green
                        
                      >
                        Package Details
                      </CardTitle>
                      <Row>
                        <Col xl="12">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <div className="col-3 mb-3">
                                <AvField
                                  name="weight"
                                  label="Weight"
                                  type="number"
                                  onChange={v => {
                                    setWeight(v.target.value)
                                  }}
                                />
                              </div>
                              <div className="col-3 mb-3 mx-3">
                                <AvField
                                  name="dimension"
                                  label="Dimension"
                                  type="text"
                                  onChange={v => {
                                    setDimension(v.target.value)
                                  }}
                                />
                              </div>
                              <div className="col-4 mb-3 mx-3">
                                <AvField
                                  type="select"
                                  name="packageSize"
                                  label="Package Size"
                                  onChange={v => {
                                    setPackageSize(v.target.value)
                                  }}
                                >
                                  <option>Select a package Size</option>
                                  {categories?.map((item, key) => (
                                    <option key={key} value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </AvField>
                              </div>
                              <div className="col-6 mb-3 align-items-center d-flex">
                                <Label check for="checkbox">
                                  {" "}
                                  Is Bulky Product :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mx-3"
                                  onColor="#626ed4"
                                  onChange={v => {
                                    setIsBulkyProduct(v)
                                  }}
                                  checked={isBulkyProduct}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col> */}
                {/* fittingData */}
                {/* <Col xl="6">
                  <Card style={{ minHeight: "200px" }}>
                    <CardBody>
                      <CardTitle
                        className="h4 mb-4"text_deals_green
                        
                      >
                        Fitting Details
                      </CardTitle>
                      <Row>
                        <div className="col-6 mb-3 align-items-center d-flex">
                          <Label check for="checkbox">
                            Is fitting needed :
                          </Label>
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mx-3"
                            onColor="#626ed4"
                            onChange={v => {
                              setIsFittingNeeded(v)
                            }}
                            checked={isFittingNeeded}
                          />
                        </div>

                        <div className="col-4 mb-3 align-items-center d-flex ">
                          <Label check for="checkbox">
                            {" "}
                            Is fitted :
                          </Label>
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mx-3"
                            onColor="#626ed4"
                            onChange={v => {
                              setIsFitted(v)
                            }}
                            checked={isFitted}
                          />
                        </div>

                        <div className="col-6 mb-3">
                          <AvField
                            name="fittingCharge"
                            placeholder="Fitting charge"
                            type="number"
                            onChange={v => {
                              setFittingCharge(v.target.value)
                            }}
                          />
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>

              {/* shippingData */}
              {/* <Row>
                <Col xl="8">
                  <Card>
                    <CardBody>
                      <CardTitle
                        className="h4 mb-4"text_deals_green
                        
                      >
                        Shipping Details
                      </CardTitle>
                      <Row>
                        <Col xl="12">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <div className="col-12 mb-3 align-items-center d-flex">
                                <Label check for="checkbox">
                                  {" "}
                                  Is ReturnViable :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mx-3"
                                  onColor="#626ed4"
                                  onChange={v => {
                                    setisReturnViable(v)
                                  }}
                                  checked={isReturnViable}
                                />
                              </div>
                              <div className="col-6 mb-3 align-items-center d-flex">
                                <Label check for="checkbox">
                                  {" "}
                                  Is extra charge needed :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mx-3"
                                  onColor="#626ed4"
                                  onChange={v => {
                                    setIsExtraChargeNeeded(v)
                                  }}
                                  checked={isExtraChargeNeeded}
                                />
                              </div>
                              <div className="col-4 mb-3">
                                <AvField
                                  name="extraCharge"
                                  placeholder="Extra charge"
                                  type="number"
                                  onChange={v => {
                                    setExtraCharge(v.target.value)
                                  }}
                                />
                              </div>

                              <div className="col-6 mb-3 align-items-center d-flex">
                                <Label check for="checkbox">
                                  {" "}
                                  isAvailableEveryWhere :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mx-3"
                                  onColor="#626ed4"
                                  onChange={v => {
                                    setIsAvailableEveryWhere(v)
                                  }}
                                  checked={isAvailableEveryWhere}
                                />
                              </div>

                              {!isAvailableEveryWhere ? (
                                <Col sm="12" md="12" className="mb-3">
                                  <Label for="basicpill-cstno-input7">
                                    Area(multiple)
                                  </Label>
                                  <div className="d-flex  flex-wrap">
                                    {map(area, (item, key) => (
                                      <div key={key} className="m-1">
                                        <input
                                          type="radio"
                                          className="btn-check"
                                          name={item?.name}
                                          checked={areaList?.includes(
                                            item?._id
                                          )}
                                          id={item?._id}
                                          autoComplete="off"
                                          value={item?._id}
                                          onClick={e =>
                                            addOrRemoveItem(
                                              areaList,
                                              setAreaList,
                                              e.target.value
                                            )
                                          }
                                        />
                                        <label
                                          className="btn btn-outline-info"
                                          htmlFor={item?._id}
                                        >
                                          {item?.name}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              <div className="col-4 mb-3 align-items-center justify-content-between d-flex">
                                <Label check for="checkbox">
                                  Area :
                                </Label>
                                <AvField
                                  type="select"
                                  name="area"
                                  onChange={v => {
                                    setAreaList(v.target.value)
                                  }}
                                >
                                  <option>Select a Area</option>
                                  {area?.map((item, key) => (
                                    <option key={key} value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </AvField>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}

              {/* Save */}
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4 text_deals_green"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create Product
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateProduct

export const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

export const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}

export const UnitsList = [
  "kg",
  "g",
  "L",
  "mL",
  "pcs",
  "nos",
  "item",
  "Ltr",
  "S",
  "Pkt",
  "M",
  "Xl",
]
